import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import PaymentMethodForm from '../PaymentMethodForm';
const Initial = ({ goToNextStep, initValues, goToDeleteStep, }) => {
    const { t } = useTranslation();
    return (initValues ? (_jsx(PaymentMethodForm, { title: t('settings.sidebars.edit_payment_method.steps.initial.title'), onSubmit: goToNextStep, initValues: initValues, cancelBtnProps: {
            onClick: goToDeleteStep,
            children: t('settings.sidebars.edit_payment_method.steps.initial.delete_btn_text'),
        }, showCancelBtn: true })) : null);
};
export default Initial;
