import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import CardSelect from '@shared/ui/misc/CardSelect';
import { ElementContainer, ItemLabel, StepForm, StepTitle, } from '@shared/ui/settings/shared';
import { MASK_CARD_NUMBER, MASK_CVV, MASK_EXPIRE_DATE } from '@app/constants/validation';
import Input from '@shared/ui/inputs/Input';
import InputMasked from '@shared/ui/inputs/InputMasked';
import Button from '@shared/ui/buttons/Button';
import validationSchema from './validation';
const DateCvvContainer = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.m,
}));
const PaymentMethodForm = ({ onSubmit, initValues, showCancelBtn = undefined, cancelBtnProps = undefined, title = undefined, }) => {
    const { t } = useTranslation();
    // TODO: check resolver type error
    const { control, handleSubmit, watch, setValue, } = useForm({
        defaultValues: initValues,
        resolver: yupResolver(validationSchema),
    });
    const handlePaymentSystemChange = (value) => {
        setValue('paymentSystem', value);
    };
    const paymentSystem = watch('paymentSystem');
    return (_jsx(StepForm, { onSubmit: handleSubmit(onSubmit), upperContent: (_jsxs(_Fragment, { children: [title ? _jsx(StepTitle, { children: title }) : null, _jsxs(ElementContainer, { children: [_jsx(ItemLabel, { children: t('settings.sidebars.add_new_payment.steps.initial.type.title') }), _jsx(CardSelect, { onChange: handlePaymentSystemChange, value: paymentSystem || '' })] }), _jsx(ElementContainer, { children: _jsx(Controller, { name: 'holderName', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(Input, Object.assign({}, field, { autoComplete: 'cc-name', InputLabelProps: { shrink: true }, label: t('settings.sidebars.add_new_payment.steps.initial.name.title'), placeholder: t('settings.sidebars.add_new_payment.steps.initial.name.placeholder'), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined })));
                        } }) }), _jsx(ElementContainer, { children: _jsx(Controller, { name: 'cardNumber', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(InputMasked, Object.assign({}, field, { autoComplete: 'cc-number', label: t('settings.sidebars.add_new_payment.steps.initial.card_number.title'), placeholder: t('settings.sidebars.add_new_payment.steps.initial.card_number.placeholder'), mask: MASK_CARD_NUMBER, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined })));
                        } }) }), _jsxs(DateCvvContainer, { children: [_jsx(ElementContainer, { children: _jsx(Controller, { name: 'expireDate', control: control, render: ({ field, fieldState }) => {
                                    var _a, _b;
                                    return (_jsx(InputMasked, Object.assign({}, field, { autoComplete: 'cc-exp', label: t('settings.sidebars.add_new_payment.steps.initial.expire_date.title'), mask: MASK_EXPIRE_DATE, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined })));
                                } }) }), _jsx(ElementContainer, { children: _jsx(Controller, { name: 'cvv', control: control, render: ({ field, fieldState }) => {
                                    var _a, _b;
                                    return (_jsx(InputMasked, Object.assign({}, field, { autoComplete: 'cc-csc', label: t('settings.sidebars.add_new_payment.steps.initial.cvv.title'), placeholder: t('settings.sidebars.add_new_payment.steps.initial.cvv.placeholder'), mask: MASK_CVV, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, hidden: true })));
                                } }) })] })] })), bottomContent: (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, type: 'submit', children: t('settings.sidebars.add_new_payment.steps.initial.btn_title') }), showCancelBtn ? (_jsx(Button, Object.assign({}, (cancelBtnProps || {}), { variant: 'tinted', color: 'error', size: 'large', fullWidth: true }))) : null] })) }));
};
export default PaymentMethodForm;
