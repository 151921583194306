import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
const PersonalInfoTitle = () => Trans({
    ns: 'translation',
    i18nKey: 'settings.tabs.personal_info.tab_title',
});
const SubscriptionTabTitle = () => Trans({ ns: 'translation', i18nKey: 'settings.tabs.subscription.tab_title' });
const getSettingsTabs = () => [
    {
        label: _jsx(PersonalInfoTitle, {}),
        value: 'personal_information',
    },
    {
        label: _jsx(SubscriptionTabTitle, {}),
        value: 'subscription',
    },
];
export default getSettingsTabs;
