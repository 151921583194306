import { RouteNames } from '@app/routing/types';
import { matchPathPartial } from '@app/routing/helpers';
// eslint-disable-next-line import/prefer-default-export
export const matchPathToSettingsTab = (pathname) => {
    let tab;
    if (matchPathPartial(RouteNames.SETTINGS_PERSONAL_INFORMATION, pathname)) {
        tab = 'personal_information';
        // } else if (matchPathPartial(RouteNames.SETTINGS_NOTIFICATIONS, pathname)) {
        //     tab = 'notifications'
    }
    else if (matchPathPartial(RouteNames.SETTINGS_SUBSCRIPTION, pathname)) {
        tab = 'subscription';
    }
    return tab;
};
