import * as yup from 'yup';
import { REGEXP_CARD_NUMBER, REGEXP_CVV, REGEXP_EXPIRE_DATE } from '@app/constants/validation';
const validationSchema = yup.object().shape({
    cardNumber: yup.string()
        .nullable()
        .required('not be empty')
        .matches(REGEXP_CARD_NUMBER, 'incorrect-number'),
    cvv: yup.string().nullable()
        .required('not be empty')
        .matches(REGEXP_CVV, 'incorrect-number'),
    expireDate: yup.string().nullable()
        .required('not be empty')
        .matches(REGEXP_EXPIRE_DATE, 'incorrect-number'),
    holderName: yup.string().nullable()
        .min(2, 'minimum 2 characters'),
});
export default validationSchema;
