import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import IconWithText from '@shared/ui/display/IconWIthText';
import { CheckmarkIcon, StepContainer, } from '@shared/ui/settings/shared';
import Button from '@shared/ui/buttons/Button';
const Final = ({ finishForm }) => {
    const { t } = useTranslation();
    return (_jsx(StepContainer, { upperContent: (_jsx(IconWithText, { IconElement: _jsx(CheckmarkIcon, {}), text: t('settings.sidebars.edit_payment_method.steps.final.text') })), bottomContent: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: finishForm, children: t('settings.sidebars.edit_payment_method.steps.final.continue_btn_text') })) }));
};
export default Final;
