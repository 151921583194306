const SIDEBAR_INNER_PROPS = {
    0: { displayCancelBtn: true },
    1: { displayCancelBtn: false },
    2: { displayCancelBtn: true },
};
var AddPaymentSteps;
(function (AddPaymentSteps) {
    AddPaymentSteps[AddPaymentSteps["Initial"] = 0] = "Initial";
    AddPaymentSteps[AddPaymentSteps["Final"] = 1] = "Final";
    AddPaymentSteps[AddPaymentSteps["Warning"] = 2] = "Warning";
})(AddPaymentSteps || (AddPaymentSteps = {}));
export { SIDEBAR_INNER_PROPS, AddPaymentSteps };
